@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

@layer components{
  .page-title{
      @apply md:text-[1.6em] font-[700] text-primary text-[1.5em] capitalize
  }
  .container{
    @apply mx-auto max-w-screen-lg p-10 lg:p-4
}
  .input{@apply w-full rounded-[5px] h-[39px] px-5 relative flex items-center}
  .label{@apply text-[#252631] font-[400] text-[14px]}
  .pryBtn{@apply bg-primary input justify-center px-5 text-xs hover:bg-secondary text-white space-x-2 }
  .pryBtnOutline{@apply pryBtn w-full bg-transparent border border-primary hover:bg-primary text-primary hover:text-white}
}

/* Remove the spinner on the number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove the spinner in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}